<template>
  <div>
    <div ref="form">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item
          v-for="item in formInlineData"
          :key="item.key"
          :label="`${item.label}：`">
          <template v-if="item.select">
            <!-- <el-select
              v-model="formInline[item.key]"
              placeholder="请选择"
              :filterable="item.search"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option
                v-for="select in item.options"
                :key="select.value"
                :label="select.label"
                :value="select.value"
              >
              </el-option>
            </el-select> -->
            <request-module-select
              v-if="item.select"
              v-model="formInline[item.key]"
              :filterable="true"
              :placeholder="item.label"
              :type="item.model" />
          </template>
          <template v-else>
            <el-input
              v-model="formInline[item.key]"
              :placeholder="`请输入${item.label}`"
              @keyup.enter.native="query"></el-input>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
        <!-- <el-tabs v-model="formInline.activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="传统送达方" name="0"></el-tab-pane>
        <el-tab-pane label="新渠道送达方" name="2"></el-tab-pane>
      </el-tabs> -->
      </el-form>
    </div>
    <div>
      <el-button
        :disabled="rowId.length === 0"
        type="warning"
        @click="dialogOrder = true"
        >批量修改订单专员</el-button
      >
      <el-button
        v-loading.fullscreen.lock="fullscreenLoading"
        @click="excelDownload"
        v-permission="['authorzed_excel']">
        导出excel
      </el-button>
    </div>
    <div
      :style="{
        height: `calc(100vh - ${withoutTableHeight}px)`,
        marginTop: '20px'
      }">
      <el-table
        ref="table"
        border
        :data="dataList"
        style="width: 100%"
        :header-cell-style="{ background: '#F4F5F8', color: '#393F4D' }"
        v-loading="loading"
        height="100%"
        @selection-change="handleSelectionChange"
        :row-key="getRowKey">
        <el-table-column type="selection" :reserve-selection="true">
        </el-table-column>
        <el-table-column
          prop="shopBrandNumber"
          :label="`送达方编号`"
          width="140" />
        <el-table-column
          prop="deliveryPartyName"
          :label="`送达方名称`"
          width="170" />
        <el-table-column
          prop="channelCooperationTypeGroupName"
          label="渠道合作类型"
          width="120">
        </el-table-column>
        <el-table-column prop="statusExplain" label="送达方状态" width="110">
          <template slot-scope="scope">
            <div>
              {{
                keyValueJson.statusExplain_authorized.find(
                  item => item.value == scope.row.statusExplain
                ).label
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="ifEnabled"
          width="110"
          align="center"
          v-if="showCloum"
          label="生效/失效">
          <template slot-scope="scope">
            <div @click.stop="">
              <!-- {{ scope.row.ifEnabled ? '停用' : '启用' }} -->
              <el-tooltip
                :content="scope.row.ifEnabled ? '停用' : '启用'"
                placement="top">
                <el-switch
                  :value="scope.row.ifEnabled"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="0"
                  :inactive-value="1"
                  @change="handleChange(scope.row)">
                </el-switch>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="legalPersonName" label="法人" width="120" />
        <el-table-column
          prop="orderAuthority"
          label="下单权限"
          width="120"
          v-if="hasMasterListOrderAuthority">
          <template slot-scope="scope">
            <!-- <el-tag
              v-if="scope.row.orderAuthority"
              :type="scope.row.orderAuthority ? 'success' : 'danger'"
              size="small">
              {{ scope.row.orderAuthority ? '是' : '否' }}
            </el-tag> -->
            <div @click.stop="">
              <!-- {{ scope.row.ifEnabled ? '停用' : '启用' }} -->
              <el-switch
                :value="scope.row.orderAuthority"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="handleChangeOrderAuthority(scope.row)">
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="授权品牌" width="140">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('授权品牌', 'brand', scope.row)">
                查看授权品牌
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="授权品牌创建时间"
          width="140" />
        <el-table-column prop="bigRegionName" label="销售部" width="140" />
        <el-table-column prop="smallRegionName" label="营销中心" width="140" />
        <el-table-column prop="code" label="销售组织" width="140">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('销售组织', 'code', scope.row)">
                查看销售组织
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="orderSpecialist" label="订单专员" width="120">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('订单专员', 'order', scope.row)">
                查看订单专员
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="dealerCode" label="经销商编号" width="150" />
        <el-table-column
          prop="distributorName"
          label="关联经销商"
          width="150" />
        <el-table-column prop="licenseNumber" label="售达方编号" width="150" />
        <el-table-column
          prop="businessLicenseName"
          label="关联售达方"
          width="150" />
        <el-table-column label="关联门店" width="150">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                @click="showDialog('门店列表', 'shop', scope.row)">
                查看门店
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <div>
              <div
                class="handle"
                v-permission="['masterList_sap']"
                @click="handleExport(scope.row.id)">
                同步到SAP
              </div>
              <div
                class="handle"
                @click="
                  showDialog('相关流程', 'dealer_authorized_brand', scope.row)
                ">
                相关流程
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBar">
      <el-pagination
        background
        layout="total,prev, pager, next,sizes"
        :total="total"
        :page-sizes="[10, 30, 50]"
        :page-size="limit"
        :current-page="page"
        @current-change="currentChange"
        @size-change="handleSizeChange" />
    </div>
    <DialogTable
      :visible.sync="dialogVisible"
      :title="title"
      :type="type"
      :dataList="tableList"
      :rowData="rowData"
      :authorizedCity="city"
      :id="id"
      @update="update" />
    <el-dialog
      :visible.sync="dialogOrder"
      width="375px"
      title="批量修改订单专员">
      <el-form :model="formData" ref="ruleForm" class="demo-ruleForm">
        <required-form-item-select
          ref="orderSpecialistRef"
          class="formItem"
          prop="bossNumber"
          v-model="formData.bossNumber"
          label="订单专员"
          labelKey="realName"
          valueKey="bossNumber"
          :selectProps="{
            filterable: true,
            remote: true,
            'popper-class': 'custom-business-select'
          }"
          :selectListeners="{}"
          :total-count="totalCount"
          :getDataList="getOrderSpecialist"
          :getNextList="getOrderSpecialist"
          @onOptionsListNotFound="onNotFoundOrderSpecialist">
          <template v-slot="{ scope }">
            <span>{{ scope.row.realName }}（{{ scope.row.bossNumber }}）</span>
          </template>
        </required-form-item-select>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resetOreder">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DialogTable from './components/dialogTable'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import RequiredBrandFormItemSelect from '@/components/CustomFormItem/requiredBrandFormItemSelect.vue'
import {
  getReceiverDataList,
  getReceiverShop,
  getReceiverBrand,
  getReceiverOrderSpecialist,
  getTheBrand,
  initializeSapDataByDataId,
  changeReceiverStatus,
  getBossList,
  resetOrderManager,
  getBrandDataList,
  excelAuthorzed,
  excelAuthorzedJson,
  getBossCorrelationInfo
} from '@/api/businessOos'

import { tableHeight } from '@/mixin/tableHeight'
import { getStorage } from '@/utils/storage'

import { excelJsonToExcel } from './fn'
import { masterJsonValue } from './json'
import { keyValueJson } from './fn'
const formInlineData = [
  {
    label: '送达方编号',
    key: 'shopBrandNumber'
  },
  {
    label: '经销商编号',
    key: 'dealerCode'
  },
  {
    label: '授权城市',
    key: 'authorizedCityName'
  },
  // {
  //   label: "授权品牌",
  //   key: "brandId",
  //   select: true,
  //   options: [],
  //   search: true
  // },
  {
    label: '法人',
    key: 'legalPersonName'
  },
  // {
  //   label: '订单专员',
  //   key: 'orderSpecialist'
  // },
  {
    label: '省份',
    key: 'province'
  },
  {
    label: '市区',
    key: 'city'
  },
  {
    label: '区/镇/县',
    key: 'area'
  },
  {
    label: '送达方状态',
    key: 'statusExplain',
    select: true,
    model: 'receiver_type'
  }
]
export default {
  components: {
    DialogTable,
    RequiredFormItemSelect,
    RequestModuleSelect,
    RequiredBrandFormItemSelect
  },
  mixins: [tableHeight],
  data() {
    return {
      keyValueJson,
      formInline: {
        // activeName: '0'
      }, // 搜索条件
      formInlineData,
      dataList: [],
      total: 0,
      limit: 10,
      page: 1,
      dialogVisible: false,
      title: '',
      type: '',
      tableList: [],
      loading: false,
      rowData: null,
      rowId: [],
      dialogOrder: false,
      formData: {}, // 订单专员数据
      totalCount: 0,
      userInfo: {},
      city: '',
      id: '',
      fullscreenLoading: false,
      selectData: [], // 获取的送达方
      userInfo: getStorage('userInfo') || {}
    }
  },
  created() {
    this.userInfo = getStorage('userInfo')
    // this.getBrandList();
    this.getDataList()
  },
  computed: {
    showCloum() {
      const userInfo = getStorage('userInfo')
      return userInfo.permList.includes('change_status')
    },
    hasMasterListOrderAuthority() {
      return this.userInfo.permList.includes('masterList_orderAuthority')
    }
  },
  methods: {
    // 导出送达方列表
    async excelDownload() {
      // await excelAuthorzed()
      this.fullscreenLoading = true
      try {
        const userInfo = getStorage('userInfo') || {}
        const { data } = await excelAuthorzedJson({
          accountId: userInfo.accountId
        })
        data.forEach(item => {
          item.statusExplain_authorized = item.statusExplain
        })
        excelJsonToExcel(masterJsonValue, data, '送达方列表')
      } catch (error) {
        console.log(error)
        this.$message.error('导出失败')
      } finally {
        this.fullscreenLoading = false
      }
    },
    // 批量修改订单专员
    async resetOreder() {
      // const ids = this.dataList.filter(item => this.rowId.includes(item.id))
      // console.log(ids.map(i=>i.shopBrandNumber));
      const res = await resetOrderManager({
        orderSpecialist: this.formData.bossNumber,
        shopBrandNumber: this.selectData.map(i => i.shopBrandNumber)
      })
      if (res.code === 0) {
        this.$message.success('修改成功')
        this.getDataList()
        this.dialogOrder = false
      }
    },
    // 获取订单专员
    getOrderSpecialist({ page, searchText }) {
      let params = {
        currPage: page,
        roleCode: 16
      }
      if (/^\d+$/g.test(searchText)) {
        params = Object.assign({}, params, { bossNumber: searchText })
      } else {
        params = Object.assign({}, params, { realName: searchText })
      }
      return getBossList(params).then(res => {
        this.total = res.totalCount
        return res.data
      })
    },
    async onNotFoundOrderSpecialist({ value: bossNumber }, callback) {
      callback(
        await getBossList({
          bossNumber,
          roleCode: 16
        }).then(res => res.data)
      )
    },
    async handleExport(id) {
      try {
        await initializeSapDataByDataId({ dataId: id, initializeDataType: 20 })
        await initializeSapDataByDataId({ dataId: id, initializeDataType: 40 })
        this.$message.success('同步成功！')
      } catch (err) {
        console.log(err)
      }
    },
    async update(role) {
      if (role === 'order') {
        const res = await getReceiverOrderSpecialist({
          shopBrandNumber: this.rowData.shopBrandNumber,
          businessLicenseId: this.rowData.businessLicenseId
        })
        // console.log(res);
        this.tableList = res.data
      }
    },
    query() {
      // console.log(this.formInline);
      this.$refs.table.clearSelection()
      this.page = 1
      this.getDataList()
    },
    // 获取传统送达方
    async getDataList() {
      this.$nextTick(() => {
        this.$refs.table.bodyWrapper.scrollTop = 0
      })

      this.loading = true
      const res = await getReceiverDataList({
        ...this.formInline,
        // channelCooperationType: this.formInline.activeName == '0' ? '' : 0,
        currPage: this.page,
        pageSize: this.limit
      })
      this.loading = false
      // console.log(res);
      if (res.code === 0) {
        this.dataList = res.data
        this.total = res.totalCount
      }
    },
    // 获取新渠道送达方
    getNewDataList() {
      // const res = await get
    },
    // 获取授权系列
    async getBrandList() {
      const res = await getBrandDataList()
      console.log(res)
      if (res.code === 0) {
        this.formInlineData[2].options = res.data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        console.log(this.formInlineData)
      }
    },
    // 获取授权品牌
    async getBrandData(data) {
      const res = await getReceiverBrand({
        businessLicenseId: data.businessLicenseId,
        authorizedCityCode: data.authorizedCityCode,
        shopBrandNumber: data.shopBrandNumber
      })
      this.tableList = res.data.map(item => {
        return {
          bigBrandName: item.brandName,
          name: item.seriesName,
          ifEnabled: item.seriesIfDelete,
          ...item
        }
      })
    },
    // 获取门店
    async getShopDataList(data) {
      const res = await getReceiverShop({
        shopBrandNumber: data.shopBrandNumber
      })
      this.tableList = res.data
    },
    // 获取销售组织代码
    async getCodeDataList(data) {
      const res = await getTheBrand({
        // brandId: data.brandId,
        shopBrandNumber: data.shopBrandNumber
      })
      this.tableList = res.data
    },
    // 获取订单专员
    async getOrderDataList(data) {
      this.rowData = data
      const res = await getReceiverOrderSpecialist({
        shopBrandNumber: data.shopBrandNumber,
        businessLicenseId: data.businessLicenseId
      })
      // console.log(res);
      this.tableList = res.data
    },
    // tab切换
    handleClick() {
      this.page = 1
      this.getDataList()
    },
    async handleChange({ id, ifEnabled }) {
      const status = ifEnabled === 1 ? 0 : 1
      const res = await changeReceiverStatus({ id, ifEnabled: status })
      if (res.code === 0) {
        this.getDataList()
      }
    },
    async handleChangeOrderAuthority({ id, orderAuthority, ifEnabled }) {
      const status = orderAuthority === 1 ? 0 : 1
      const res = await changeReceiverStatus({
        id,
        orderAuthority: status,
        ifEnabled
      })
      if (res.code === 0) {
        this.getDataList()
      }
    },
    // 页面切换
    currentChange(page) {
      this.page = page
      this.getDataList()
    },
    // 页数切换
    handleSizeChange(page) {
      this.page = 1
      this.limit = page
      this.getDataList()
    },
    handleSelectionChange(val) {
      // console.log(val)
      this.selectData = val
      this.rowId = val.map(item => {
        return item.id
      })
    },
    // 点击行实现点击复选框
    rowClick(row, column, event) {
      this.$refs.table.toggleRowSelection(row)
    },
    getRowKey(row) {
      return row.id
    },
    // 查看
    showDialog(title, type, data) {
      this.dialogVisible = true
      this.title = title
      this.type = type
      this.city = data.city
      this.rowData = data
      this.id = data.id
      switch (type) {
        case 'brand':
          //查看授权品牌
          this.getBossCorrelationInfo({
            shopBrandNumber: data.shopBrandNumber,
            userId: this.userInfo.accountId,
            dataType: 'party'
          })
          break
        case 'shop':
          //查看门店
          this.getBossCorrelationInfo({
            shopBrandNumber: data.shopBrandNumber,
            userId: this.userInfo.accountId,
            dataType: 'shop'
          })
          break
        case 'code':
          this.getCodeDataList(data)
          break
        case 'order':
          this.getOrderDataList(data)
          break
      }
    },
    getBossCorrelationInfo(params) {
      getBossCorrelationInfo(params).then(res => {
        this.tableList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixin';
@import '@/styles/colors';

.paginationBar {
  @include flex(center, center);
  width: 100%;
  height: 60px;
  overflow-x: auto;
}

.handle {
  color: $primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
